import React from 'react'
import '../styles/main.css'
import {
    saquib,
    dimitri,
    dylan,
    howard,
    jen,
    yean
} from '../utils/images'

const Solvers = () => {
    return (
        <>
            <section className='home-page'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8 col-md-10 col-sm-12 col-12'>
                            <div className='home-content pt-lg-0 pt-md-0 pt-0 pb-lg-4 pb-md-4 pb-4'>
                                <div className='row'>
                                    <div className='col-lg-2 col-md-2 col-sm-2 col-12 d-flex align-items-center'>
                                        <div className='setion_count page_title_count'>
                                            <span>03</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-10 col-md-10'>
                                        <div className='set_title page_title'>
                                            <h1 className='m-0'>Solvers</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-end'>
                                <div className='col-lg-11 col-md-12 col-sm-12'>
                                    <div className='sec_seprator position-relative mb-lg-5 mb-4'>
                                    </div>
                                    <div className='row pt-lg-5 pb-lg-5 pb-md-4 pb-5'>
                                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                                            <img src={saquib} width="200px" />
                                        </div>
                                        <div className='col-lg-8 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-4 mt-4'>
                                            <div className='solver_detail'>
                                                <h3>SAQIB MAUSOOF</h3>
                                                <h6>Data Strategy and Solutions</h6>
                                                <p>Saqib Mausoof is a digital marketer with expertise in media planning, data strategy, and platform monetization. He is a consummate professional and has 20+ years of experience in building global marketing teams with diverse talent and commercial acumen. His formative experience includes Ask.com, Esurance, John Deere, IPG Mediabrands, and Liveramp.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pt-lg-5 pb-lg-5 pb-md-4 pb-5'>
                                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                                            <img src={jen} width="200px" />
                                        </div>
                                        <div className='col-lg-8 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-4 mt-4'>
                                            <div className='solver_detail'>
                                                <h3>Jen Yip</h3>
                                                <h6>Market Research & Survey Design</h6>
                                                <p>Jen Yip is a technology and media veteran who has held leadership roles in early-stage start-ups and large companies including DoubleClick, NBC Universal, and InMobi.  She has also been a collaborator to organizations in healthcare tech, media, SaaS platforms, and social impact.Her specialties include scaling strategies, brand marketing, customer intelligence, and lean project management.  Jen's customer-centered approach is rooted in systems thinking and agile principles to drive results. Jen holds an M.B.A from New York University and a B.S. from Cornell University.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pt-lg-5 pb-lg-5 pb-md-4 pb-5'>
                                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                                            <img src={dimitri} width="200px" />
                                        </div>
                                        <div className='col-lg-8 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-4 mt-4'>
                                            <div className='solver_detail'>
                                                <h3>DIMITRI VAYNBLAT</h3>
                                                <h6>Data Science and Tooling</h6>
                                                <p>Dimitri Vaynblat is a successful entrepreneur and acknowledged expert in the field of data science, AI, big data technologies, and product development, with 20 years of experience in designing and delivering highly scalable complex systems as well as building and leading large high-performance engineering and product organizations. Dimitri co-founded and held CTO and VP of Products positions with innovative digital marketing and advertisement technology companies, such as RadiumOne, RedAril, and NebuAd. He is currently principal at Steppechange, a data science-focused software house.</p>
                                                <p className='mt-4'>Dimitri has a Ph.D. in Applied Mathematics from MIT and He has filed multiple patents in software design around social graphs, identity linkages, and hi-frequency transactional processing.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pt-lg-5 pb-lg-5 pb-md-4 pb-5'>
                                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                                            <img src={yean} width="200px" />
                                        </div>
                                        <div className='col-lg-8 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-4 mt-4'>
                                            <div className='solver_detail'>
                                                <h3>YEAN CHEONG</h3>
                                                <h6>Data Strategy and Solutions, APAC</h6>
                                                <p>Yean Cheong is an international C-suite marketer, digital strategist, and change catalyst with 20+ years of experience, having worked in diverse industries and cities across the Asia Pacific region, in both client and agency roles.</p>
                                                <p className='mt-4'>Yean has held multiple roles with IPG Mediabrands, started as their APAC Chief Digital Head, working on UM and Initiative’s global client portfolios including Microsoft, Mastercard, and Johnson & Johnson. She was Head of Cadreon APAC since its inception, and successfully launched and managed Cadreon across the region, delivering integrated solutions that combined programmatic media and data across all digital channels including search, mobile and social.</p>
                                                <p className='mt-4'>In 2017, she was recognized as one of APAC Outstanding Technology Leaders in Campaign360 "Women Leading Change Awards", acknowledging women who have worked to ignite positive change through the introduction of new technology to the industry.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pt-lg-5 pb-lg-5 pb-md-4 pb-5'>
                                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                                            <img src={howard} width="200px" />
                                        </div>
                                        <div className='col-lg-8 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-4 mt-4'>
                                            <div className='solver_detail'>
                                                <h3>HOWARD STEINBERG</h3>
                                                <h6>Client Success and Delivery</h6>
                                                <p>Howard Steinberg is an experienced, customer-facing high-tech professional focused on B2B client engagements, collecting and executing against specific requirements. He has consistently displayed effective communication, focus on clients' needs in a myriad of technology verticals. He tends to balance data-driven insights via his analytical focus along with his gut, based upon his broad and diverse experience. He is an effective leader and a willing listener. </p>
                                                <p className='mt-4'>Howard's background includes business development, forging strategic partnerships with carriers/operators, digital agencies, data providers, app store platforms, SDK providers, device OEMs, native smartphone and mobile web app design/development shops, syndication network partners, monetization platforms, payment processors and content providers.</p>
                                                <p className='mt-4'>Hands-on experience includes verticals such as mobile, local search, finance, retail, finance, automotive, music, sports, and news. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pt-lg-5 pb-lg-5 pb-md-4 pb-5'>
                                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 d-flex align-items-center'>
                                            <img src={dylan} width="200px" />
                                        </div>
                                        <div className='col-lg-8 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-4 mt-4'>
                                            <div className='solver_detail'>
                                                <h3>DYLAN FREITAS-D'LOUHY</h3>
                                                <h6>Artist in Residence</h6>
                                                <p>Raised on traditional art forms, educated in business marketing at San Francisco State university, and through various avenues has worked on web, films, events, entertainment, and virtual exhibits. Building directly, sharing a stage, or collaborating with a range of brands, artists, and globally recognized talent.  Consulting for 15+ years to small businesses, or larger organizations and institutions such as Canal+, Architecture for Humanity, Universal Pictures, Mongoose Bicycles, Kali Protectives, & Loud! Records, he aims at the maximizing experience.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sec_seprator position-relative mt-lg-4 mb-lg-5 mb-4'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Solvers