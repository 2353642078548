import React from "react";
import "../styles/main.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { saquib, dimitri, dylan, howard, jen, yean } from "../utils/images";
import axios from "axios";
import { toast } from "react-toastify";
// import useContact from "../hooks/useContact";
const initialValues = {
  floatingInput: "",
  floatingPassword: "",
  floatingTextarea: "",
};

const Contacts = () => {

  const validationSchema = Yup.object({
    floatingInput: Yup.string().required("Name is required!"),
    floatingPassword: Yup.string().required("Email is required!"),
    floatingTextarea: Yup.string().required("Comment is required!"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      try {
       let data = {
        name:values.floatingInput,
        email:values.floatingPassword,
        comment:values.floatingTextarea,
      }
        await axios.post("https://1bwh7gwqn7.execute-api.us-east-1.amazonaws.com/prod/api/bemx-contact", data);
        toast.success("Your Request was sent to the admin successfully!");
      } catch (error) {
  
        // console.error("Error creating employee:", error);
        // toast.error("Contact Data Add Failed!");
      }
      formik.resetForm();
      // await contactData({
      //   name:data.floatingInput,
      //   email:data.floatingPassword,
      //   comment:data.floatingTextarea,
      // })
    },
  });
  return (
    <>
      <section className="home-page">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 col-sm-12 col-12">
              <div className="home-content pt-lg-0 pt-md-0 pt-0 pb-lg-4 pb-md-4 pb-4">
                <div className="row">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-12 d-flex align-items-center">
                    <div className="setion_count page_title_count">
                      <span>04</span>
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-10">
                    <div className="set_title page_title">
                      <h1 className="m-0">Contact</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-lg-5 mt-md-5 mt-4 pb-lg-5">
                <div className="col-lg-4 col-md-12 col-sm-12 offset-lg-1 mt-lg-5 mt-md-5 mt-4">
                  <div className="adress_tab">
                    <h5>SAN FRANCISCO</h5>
                    <a href="mailto:ssm@brandexmachina.com">
                      mailto:ssm@brandexmachina.com
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 my-lg-5 my-md-5 my-5">
                <di className="contact_form mt-lg-5 mt-md-5 mt-0">
                  <form>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class={`form-control ${ formik.touched.floatingInput && formik.errors.floatingInput   ? "is-invalid"   : "" }`}
                            id="floatingInput"
                            placeholder="Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.floatingInput}                           
                          />
                          <label for="floatingInput">Name</label>
                          <div className="invalid-feedback">
                            {formik.errors.floatingInput}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                        <div class="form-floating">
                          <input
                            type="email"
                            class={`form-control ${ formik.touched.floatingPassword && formik.errors.floatingPassword   ? "is-invalid"   : "" }`}
                            id="floatingPassword"
                            placeholder="Email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.floatingPassword}                          
                          />
                          <label for="floatingEmail">Email</label>
                          <div className="invalid-feedback">
                            {formik.errors.floatingPassword}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div class="form-floating">
                          <textarea
                            class={`form-control ${ formik.touched.floatingTextarea && formik.errors.floatingTextarea   ? "is-invalid"   : "" }`}
                            placeholder="Leave a comment here"
                            id="floatingTextarea"
                            style={{ height: "100px" }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.floatingTextarea}
                          ></textarea>
                          <label for="floatingTextarea">Comments</label>
                          <div className="invalid-feedback">
                            {formik.errors.floatingTextarea}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-lg-5 mt-md-5 mt-4 d-flex justify-content-center">
                        <button
                          className="btn btn-primary"
                          onClick={formik.handleSubmit}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </di>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contacts;
