import React from 'react'
import Header from './header'
import Footer from './footer'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../pages/home';
import Solvers from '../pages/solvers';
import Contacts from '../pages/contact';
import Clients from '../pages/clients';
import Solutions from '../pages/solutions';
import Marketramp from '../pages/marketramp';
const Layout = () => {
  return (
    <div>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="solvers" element={<Solvers />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="clients" element={<Clients />} />
          <Route path="solutions" element={<Solutions />} />
          <Route path="marketramp" element={<Marketramp />} />
        </Routes>
      </BrowserRouter>
      {/* <Footer /> */}
    </div>
  )
}

export default Layout