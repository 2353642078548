import React from 'react'
import '../styles/main.css'
import {
    remp,
    rempcir,
    ramp_bottom_icon
} from '../utils/images'

const Clients = () => {
    return (
        <>
            <section className='home-page'>
                <div className='container'>
                    <div className='row justify-content-center mb-5'>
                        <div className='col-lg-8 col-md-10 col-sm-12 col-12'>
                            <div className='col-lg-8 col-sm-12 col-12'>
                                <img src={remp} width={'100%'} />
                            </div>
                            <div className='col-12 mt-4'>
                                <div className='set_title ram_title text-lg-end text-md-start text-start'>
                                    <h1>Market Ramp</h1>
                                </div>
                            </div>
                            <div className='row platform_content marketramp client_quote platform_items justify-content-end mt-4  flex-row flex-md-row flex-column-reverse'>
                                <div className='col-lg-9 col-md-12 col-sm-12 col-12'>
                                    <p className='mb-4'>Market Ramp, a Brand ex Machina operating business, is a collaboration of Silicon Valley tech industry veterans. Together, the team counts more than 70 years, collectively, of professional experience in business development, sales, and data & analytics arenas. Our focus rest on working with startups and more established companies based in the US and overseas to help define - and/or to execute - growth strategies and to gain new users/customers and strategic partners across their given business.</p>
                                    <p className='mb-4'>We’ve found that while many accelerators can provide startups with a great, early-stage, path to success, these programs typically end before the startup is fully capable of standing on its own. That’s where RAMP comes in and works with the startup through their next phase(s) of growth. </p>
                                    <p>Key questions/challenges these start-ups face:</p>
                                    <ul className='mb-4'>
                                        <li>Is our product ready for the US market?</li>
                                        <li>Is our market positioning and message relevant, differentiated, and compelling?</li>
                                        <li>Which doors do we knock on that are most likely to open?</li>
                                        <li>Do we hire staff?  An agency?  Consultants?</li>
                                    </ul>
                                    <p className='mb-4'>How We Engage</p>
                                    <p className='mb-4'>Please contact us – <u>sales@brandexmachina.com</u> - to learn more about RAMP and how we can knowledge share, contribute to your strategy, execute in sync with you … or, on your behalf, aid in fundraising efforts and/or employ other strategies to provide a lasting impact to your business.</p>
                                    <p className='mb-4'>Our unique combination of cross-discipline expertise offers a unified, coordinated, and holistic solution to the thoughtfully go-to-market in the US and beyond</p>
                                    <p className='mb-4'>Our Team</p>
                                    <p className='mb-4'><a href="https://www.linkedin.com/in/jensyip/" target='_blank' style={{ color: '#fff', fontWeight: '700' }}>Jen Yip</a> is a technology and media veteran who has held leadership roles in early-stage start-ups and large companies including DoubleClick, NBC Universal, and InMobi.  She has also been a collaborator to organizations in healthcare tech, media, SaaS platforms, and social impact.</p>
                                    <p className='mb-4'>Her specialties include scaling strategies, brand marketing, customer intelligence, and lean project management.  Jen's customer-centered approach is rooted in systems thinking and agile principles to drive results. Jen holds an M.B.A from New York University and a B.S. from Cornell University.</p>
                                    <p className='mb-4'><a href="https://www.linkedin.com/in/jensyip/" target='_blank' style={{ color: '#fff' }}>Howard Steinberg</a> is a long-tenured business development and sales professional working with and for a small number of tech and digital media clients. The focus revolves around direct and channel sales, account management, strategic alliances, and partner management. He has worked directly with startups from the US, Argentina, Croatia, the Czech Republic, France, Georgia, Ireland, Israel, Japan, Korea, Mexico, Poland, and Turkey.</p>
                                    <p className='mb-5'>He’s held F/T tenures at Lexus/Toyota, TeamOne Advertising, Ask.com/InterActive Corp, and consulting engagements with Airship, Mobclix, Volusion, Huawei, Vermeulen Group, US Market Access Center, and Bridgehead Media. Howard holds a BA, Mathematics from Binghamton (NY) University and an MBA from the Marshall School of Business @ the University of Southern California.</p>
                                    <img src={ramp_bottom_icon} width={'100%'}/>
                                </div>
                                <div className='col-lg-3 col-12 col-sm-12 col-12 text-right mb-lg-0 mb-4'>
                                    <div className='d-flex  justify-content-lg-end justify-content-md-end justify-content-center'>
                                        <img src={rempcir} width={'100%'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Clients