import React, { useState, useEffect } from 'react';
import './App.css';
import Layout from './layout/layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [addClass, setAddClass] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAddClass(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={`${addClass ? 'animation_effect' : ''}`}>
      <ToastContainer />
      <Layout />
    </div>
  );
}

export default App;

