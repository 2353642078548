import React from 'react'
import '../styles/main.css'
import {
    bem,
    mv,
    market
} from '../utils/images'

const Clients = () => {
    return (
        <>
            <section className='home-page'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8 col-md-10 col-sm-12 col-12'>
                            <div className='home-content pt-lg-0 pt-md-0 pt-0 pb-lg-4 pb-md-4 pb-4'>
                                <div className='row'>
                                    <div className='col-lg-2 col-md-2 col-sm-2 col-12 d-flex align-items-center'>
                                        <div className='setion_count page_title_count'>
                                            <span>01</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-10 col-md-10'>
                                        <div className='set_title page_title'>
                                            <h1 className='m-0'>Solutions</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-end mt-5 pt-lg-5 pt-md-5 pt-0'>
                                <div className='col-lg-12 col-md-12 col-sm-12 pt-lg-5 mt-lg-5 mt-md-5'>
                                    <div className='client-logo solutions_p w-100'>
                                        <div className='row g-5'>
                                            <div className='col-lg-4 col-md-3 col-sm-12 col-12 d-flex align-items-start justify-content-center'>
                                                <div className='text-center'>
                                                    <img src={bem} width={'110px'} height={'110px'} />
                                                    <h2 className='mt-4'>Platform Development</h2>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-3 col-sm-12 col-12 d-flex align-items-start justify-content-center'>
                                                <div className='text-center'>
                                                    <img src={mv} width={'110px'} height={'110px'} />
                                                    <h2 className='mt-4'>Research & Tech
                                                        Advisory</h2>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-3 col-sm-12 col-12 d-flex align-items-start justify-content-center'>
                                                <div className='text-center'>
                                                    <img src={market} width={'110px'} height={'110px'} />
                                                    <h2 className='mt-4'>Market<br />Activation</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sec_seprator position-relative mt-lg-4 mt-md-4 mt-4 mb-lg-4 mb-4'>
                                    </div>

                                    <div className='w-100 platform_content client_quote platform_items'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <h3>Platform Development</h3>
                                            </div>
                                        </div>
                                        <div className='row flex-lg-row flex-md-column-reverse flex-column-reverse'>
                                            <div className='col-lg-9 col-md-12 col-sm-12 col-12'>
                                                <p>Platform development from ideation to design, development, and deployment of cloud-based Adtech & Martech solutions that can plug into your marketing stack</p>
                                                <ul>
                                                    <li>Services include data cleanrooms, media mix models & optimizers, and performance reporting</li>
                                                    <li><i>Clients include Armcandy, Cosima, Unique </i></li>
                                                </ul>
                                            </div>
                                            <div className='col-lg-3 col-12 col-sm-12 col-12 text-right mb-lg-0 mb-4'>
                                                <div className='d-flex  justify-content-lg-end justify-content-md-end justify-content-center'>
                                                    <img src={bem} width={'166px'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sec_seprator position-relative mt-lg-4 mt-md-4 mt-4 mb-lg-4 mb-4'>
                                    </div>

                                    <div className='w-100 platform_content client_quote platform_items'>
                                        <div className='row'>
                                            <div className='col-12 text-lg-end text-md-start text-start'>
                                                <h3>Research & Tech Advisory  </h3></div>
                                        </div>
                                        <div className='row '>
                                            <div className='col-lg-3 col-12 col-sm-12 col-12 text-right mb-lg-0 mb-4'>
                                                <div className='d-flex justify-content-lg-start justify-content-md-start justify-content-center'>
                                                    <img src={mv} width={'166px'} />
                                                </div>
                                            </div>
                                            <div className='col-lg-9 col-md-12 col-sm-12 col-12'>
                                                <p style={{ fontWeight: '600' }}>Research and tech advisory customized for your needs with a people-centered and data-informed approach</p>
                                                <ul>
                                                    <li><i>Services include RFP's, tech consulting, vendor selection, survey design and administration, purchase driver, and brand consideration analysis</i></li>
                                                    <li><i>Clients include Antonio&Paris, Audiencerate, NBCU Peacock</i></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sec_seprator position-relative mt-lg-4 mt-md-4 mt-4 mb-lg-4 mb-4'>
                                    </div>
                                    <div className='w-100 platform_content client_quote platform_items'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <h3>Market Activation </h3>
                                            </div>
                                        </div>
                                        <div className='row flex-lg-row flex-md-column-reverse flex-column-reverse'>
                                            <div className='col-lg-9 col-md-12 col-sm-12 col-12'>
                                                <p>Market Activation with a focus on go-to-market strategy for agencies, brands, consultancies, and publishers. </p>
                                                <ul>
                                                    <li>Services include product-market fit, messaging, channel sales strategy, and commercial packaging</li>
                                                    <li><i>lients included CreatorIQ, InMobi, Liveramp</i></li>
                                                </ul>
                                            </div>
                                            <div className='col-lg-3 col-12 col-sm-12 col-12 text-right mb-lg-0 mb-4'>
                                                <div className='d-flex  justify-content-lg-end justify-content-md-end justify-content-center'>
                                                    <img src={market} width={'166px'} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sec_seprator position-relative my-5'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Clients