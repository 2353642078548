import React from 'react'
import '../styles/main.css'
import {
    mkins,
    anto,
    peacock,
    liveramp,
    inmobi,
    armcandy
} from '../utils/images'

const Clients = () => {
    return (
        <>
            <section className='home-page'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8 col-md-10 col-sm-12 col-12'>
                            <div className='home-content pt-lg-0 pt-md-0 pt-0 pb-lg-4 pb-md-4 pb-4'>
                                <div className='row'>
                                    <div className='col-lg-2 col-md-2 col-sm-2 col-12 d-flex align-items-center'>
                                        <div className='setion_count page_title_count'>
                                            <span>02</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-10 col-md-10'>
                                        <div className='set_title page_title'>
                                            <h1 className='m-0'>Clients</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row justify-content-end'>
                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    <div className='sec_seprator position-relative mb-lg-5 mb-4'>
                                    </div>
                                    <div className='client-logo w-100'>
                                        <div className='row g-5'>
                                            <div className='col-lg-4 col-md-3 col-sm-12 col-12 d-flex align-items-center justify-content-center'>
                                                <img src={mkins} width={'100%'} />
                                            </div>
                                            <div className='col-lg-4 col-md-3 col-sm-12 col-12 d-flex align-items-center justify-content-center'>
                                                <img src={anto} width={'170px'} />
                                            </div>
                                            <div className='col-lg-4 col-md-3 col-sm-12 col-12 d-flex align-items-center justify-content-center'>
                                                <img src={peacock} width={'221px'} />
                                            </div>
                                            <div className='col-lg-4 col-md-3 col-sm-12 col-12 d-flex align-items-center justify-content-center'>
                                                <img src={liveramp} width={'248px'} />
                                            </div>
                                            <div className='col-lg-4 col-md-3 col-sm-12 col-12 d-flex align-items-center justify-content-center'>
                                                <img src={inmobi} width={'199px'} />
                                            </div>
                                            <div className='col-lg-4 col-md-3 col-sm-12 col-12 d-flex align-items-center justify-content-center'>
                                                <img src={armcandy} width={'256px'} style={{ filter: 'grayscale(1)' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sec_seprator position-relative mt-lg-4 mt-md-4 mt-4 mb-lg-5 mb-4'>
                                    </div>

                                    <div className='w-100 platform_content client_quote mb-5 pb-5'>
                                        <p>
                                            <i>
                                                "We have worked with Brand Ex Machina as reliable partners in helping us develop privacy-first marketing for Unique from ideation through to design and development of our EECO platform,”
                                            </i> <b>Warren Lapa, <a href="http://www.idunique.com/" target="_blank">CEO of Unique</a></b>
                                        </p>
                                        <p>
                                            <i>
                                                "The team at BrandExmachina delivered great data analytical insight for our client.  Not only were they thorough in digging into the data from many different perspectives but they translated the many data points into actionable next steps related to our marketing activities.  The upfront collaboration was on point.  I highly recommend this team with regards to quantitative research projects as well as business trending."
                                            </i>
                                            <b> Antonio Buchanan, <a href="https://antonioandparis.com/#1" target="_blank">Co-CEO, Antonio&Paris</a></b>
                                        </p>
                                        <a href="contacts" className='contact_redirecr pt-4 d-block'>
                                            CONTACT US
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Clients