import React, { useState, useEffect } from 'react'
import '../styles/main.css'

const Header = () => {
	const [clickButton, setClickButton] = useState(false)
	const [ActiveValue, setActiveValue] = useState('bexM')
	const hanldeButton = () => {
		setClickButton(!clickButton)
	}

	useEffect(() => {
		const fetchData = async () => {
			debugger
			try {
				const pathSegments = window.location.pathname.split('/');
				const value = pathSegments[1];
				setActiveValue(value);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);
	return (
		<header>
			<div className='container'>
				<div className='row'>
					<div className='col-12'>
						<div className='header-toggle d-flex align-items-center justify-content-between  pt-4 mt-1'>
							<div className='logo'>
								<a href="/">
								<h1 className='m-0'>Brand <span>Ex Machina</span></h1>
								</a>
							</div>
							<div>
								<div className={clickButton == false ? ('show  d-block') : 'nav_active'}>
									<button onClick={hanldeButton}>
										<svg preserveAspectRatio="none" className='show_nav' data-bbox="0 11 50 29" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 11 50 29" role="presentation" aria-hidden="true"><g><path d="M50,11 v3 h-50 v-3 h50z"></path><path d="M50,24 v3 h-50 v-3 h50z"></path><path d="M50,37 v3 h-50 v-3 h50z"></path></g></svg>
										<svg preserveAspectRatio="xMidYMid meet" className='hide_nav' data-bbox="25.9 25.9 148.2 148.2" xmlns="http://www.w3.org/2000/svg" viewBox="25.9 25.9 148.2 148.2" role="presentation" aria-hidden="true">
											<g>
												<path d="M171.3 158.4L113 100l58.4-58.4c3.6-3.6 3.6-9.4 0-13s-9.4-3.6-13 0L100 87 41.6 28.7c-3.6-3.6-9.4-3.6-13 0s-3.6 9.4 0 13L87 100l-58.4 58.4c-3.6 3.6-3.6 9.4 0 13s9.4 3.6 13 0L100 113l58.4 58.4c3.6 3.6 9.4 3.6 13 0s3.5-9.5-.1-13z"></path>
											</g>
										</svg>
									</button>
								</div>
							</div>
						</div>
						<div className={clickButton == false ? ('header_nev d-none') : 'header_nev d-flex'}>

							{/* <ul className='w-100'>

                                <li className='active'>
                                    <a href="/">BexM</a>
                                </li>
                                <li className='sub_nav position-relative'>
                                    <a href="solutions">Solutions</a>
                                    <li className='sub_nav-itm'>
                                        <a href="marketramp">Market Ramp</a>
                                    </li>
                                </li>
                                <li>
                                    <a href="clients">Clients</a>
                                </li>
                                <li>
                                    <a href="solvers">Solvers</a>
                                </li>
                                <li>
                                    <a href="contacts">Contact</a>
                                </li>
                            </ul> */}

							<ul className='w-100'>

								<li className={ActiveValue == '' ? 'active' : ''}>

									<a href="/">BexM</a>
								</li>

								<li className={ActiveValue == 'solutions' ? 'active sub_nav position-relative' : 'sub_nav position-relative'}  >
									<a href="solutions">Solutions</a>

									<li className={ActiveValue == 'marketramp' ? 'active sub_nav-itm' : 'sub_nav-itm'}
									>
										<a href="marketramp">Market Ramp</a>
									</li>
								</li>

								<li className={ActiveValue == 'clients' ? 'active' : ''}  >
									<a href="clients">Clients</a>
								</li>
								<li className={ActiveValue == 'solvers' ? 'active' : ''}>
									<a href="solvers">Solvers</a>
								</li>
								<li className={ActiveValue == 'contacts' ? 'active' : ''} >
									<a href="contacts">Contact</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header