import React from 'react'
import '../styles/main.css'
import {
    bem,
    mv,
    brand_img,
    market
} from '../utils/images'

const Home = () => {
    return (
        <>
            <section className='home-page'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-8 col-md-10 col-sm-12 col-12'>
                            <div className='home-content py-lg-5 py-md-5 py-4'>
                                <div className='row'>
                                    <div className='col-lg-2 col-md-2 col-sm-2 col-12'>
                                        <div className='setion_count'>
                                            <span>00</span>
                                        </div>
                                    </div>
                                    <div className='col-lg-10 col-md-10'>
                                        <div className='set_title'>
                                            <h1 className='m-0'>Brand <span>Ex Machina</span></h1>
                                            <h1 className='m-0'>The Active Side of Strategy</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='sec_seprator home_seprator position-relative'>
                            </div>
                            <div className='page_content mt-lg-4 mt-md-4 mt-4 pt-lg-3 pb-lg-4 pb-md-4 pb-4 mb-lg-3'>
                                <div className='row justify-content-center' >
                                    <div className='col-12'>
                                        <h6>Brand Ex Machina is a marketing collective that helps clients find smart solutions through deliberate execution of marketing strategy. We are focused on three areas. </h6>
                                    </div>
                                </div>
                            </div>
                            <div className='platform'>
                                <div className='row pb-lg-4 pb-md-4 pb-4 mb-lg-3'>
                                    <div className='col-lg-2 col-md-2 col-sm-12 d-flex align-items-start justify-content-lg-end justify-content-md-end justify-content-start mb-lg-0 mb-md-0 mb-4'>
                                        <img src={bem} width={'55px'} />
                                    </div>
                                    <div className='col-lg-10 col-md-10 col-sm-10'>
                                        <div className='platform_content'>
                                            <p className='m-0'>
                                            <b>Platform development from ideation to design, development, and deployment of cloud-based Adtech & Martech solutions that can plug into your marketing stack</b></p>
                                            <p className='m-0'><i>Services include data cleanrooms, media mix models, channel optimizers, and performance reporting</i></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pb-lg-4 pb-md-4 pb-4 mb-lg-3'>
                                    <div className='col-lg-2 col-md-2 col-sm-12 d-flex align-items-start  justify-content-lg-end justify-content-md-end justify-content-start mb-lg-0 mb-md-0 mb-4'>
                                        <img src={mv} width={'55px'} />
                                    </div>
                                    <div className='col-lg-10 col-md-10 col-sm-10'>
                                        <div className='platform_content'>
                                            <p className='m-0'><b>Research and tech advisory customized for your needs with a people-centered and data-informed approach</b></p>
                                            <p className='m-0'><i>Services include RFP's, tech consulting, vendor selection, survey design and administration, purchase driver, and brand consideration analysis</i></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row pb-lg-4 pb-md-4 pb-4 mb-lg-3'>
                                    <div className='col-lg-2 col-md-2 col-sm-12 d-flex align-items-start  justify-content-lg-end justify-content-md-end justify-content-start mb-lg-0 mb-md-0 mb-4'>
                                        <img src={market} width={'55px'} />
                                    </div>
                                    <div className='col-lg-10 col-md-10 col-sm-10'>
                                        <div className='platform_content'>
                                            <p className='m-0'><b>Market Activation</b> with a focus on go-to-market strategy with agencies, brands, consultancies, and publishers.</p>
                                            <p className='m-0'><i>Services include product-market fit, messaging, channel sales strategy, and commercial packaging</i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='page_steps position-relative pb-4'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                                        <a href="solutions" className='text-center'>
                                            <h2>01</h2>
                                            <p>Solutions</p>
                                        </a>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                                        <a href="clients" className='text-center'>
                                            <h2>02</h2>
                                            <p>Clients</p>
                                        </a>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                                        <a href="solvers" className='text-center'>
                                            <h2>03</h2>
                                            <p>Solvers</p>
                                        </a>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-12 col-12'>
                                        <a href="contacts" className='text-center'>
                                            <h2>04</h2>
                                            <p>Contact</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='brand_ex mb-4'>
                                <h3 className='mb-4'>The name Brand Ex Machina™  is a play on the Latin phrase Deux Ex Machina, i.e. God from Machine. It was used as a plot device in ancient greek theatre to resolve the unsolvable problem by divine intervention.  </h3>
                                <h3 className='m-0'>The logo depicts an intertwined reef knot or between an electric wire (machine) with an organic rope (brand). The knot, also known as the Hercules knot, is unique in that it may be tied and tightened with both ends.</h3>
                            </div>
                            <div className='brand_ex_img mb-lg-5 mb-md-4 mb-4 text-center'>
                                <img src={brand_img} />
                            </div>
                            <div className='brand_ex bran_cali mb-4 pb-lg-4 position-relative pt-4'>
                                <h3 className='mb-0'>Brand Ex Machina, LLC (™) is incorporated in California, and carries relevant E&O insurance.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home